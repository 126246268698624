import React, { useState, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { Typography } from 'antd';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { Col, Row, Space, Select, Radio } from 'antd';
import { Upload, notification } from 'antd';
import { humanize } from '../utils';
import axios from 'axios';

const { Title } = Typography;

const { Dragger } = Upload;

const BulkUpload = (props) => {
  const { user } = props
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [documentType, setDocumentType] = useState('TEMPLATES')
  const [templateDataType, setTemplateDataType] = useState(null)
  const [insuranceType, setInsuranceType] = useState(null)
  const [timestamp, setTimestamp] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [supportedDataTypes, setSupportedDataTypes] = useState(null)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/data-types?document_type=TEMPLATES`, { headers: { 'Authorization': 'Bearer ' + user.token } })
      .then(res => {
        console.log('====================================');
        console.log(res.data);
        console.log('====================================');
        setSupportedDataTypes(res.data)
      })
  }, [user.token])

  const downloadTemplate = () => {
    let url = `https://${process.env.REACT_APP_S3_DATA_BUCKET}.s3.ap-south-1.amazonaws.com/${user.broker_details.S3_FOLDER_NAME}/SAMPLE_TEMPLATES/${templateDataType}__${insuranceType}.csv`;
    console.log(url)
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${templateDataType}__${insuranceType}.csv`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });

  }

  const populateDocumentTypeField = () => {
    return (
      <Form.Item
        label="Document Type"
        name="docuemntType"
        rules={[{ required: true, message: 'Please select a Document Type' }]}
        tooltip={{ title: 'Type of the document that you are going to upload. If your data is in Excel Templates, select Policy Template(s) option and if your data is in Policy PDFs, select Policy PDF(s) option', icon: <InfoCircleOutlined /> }}
      >
        <Radio.Group defaultValue="TEMPLATES" buttonStyle="solid" value={documentType} onChange={setDocumentType}>
          <Radio.Button value="TEMPLATES">Policy Template(s)</Radio.Button>
          <Radio.Button value="DOCUMENTS" disabled>Policy PDF(s)</Radio.Button>
        </Radio.Group>
      </Form.Item>
    )
  }

  const populateTemplateDataTypeField = () => {
    let temp_list = [];
    let templateDataTypeOptions = (supportedDataTypes || []).map(i => {
      if (temp_list.includes(i.data_type)) {
        return null;
      }
      if (documentType !== undefined && documentType !== null) {
        if (i.document_type === documentType) {
          temp_list.push(i.data_type)
          return { value: i.data_type, label: humanize(i.data_type) }
        } else {
          return null;
        }
      }
      temp_list.push(i.data_type)
      return { value: i.data_type, label: humanize(i.data_type) }
    })
    templateDataTypeOptions = templateDataTypeOptions.filter((x) => x ? true : false)
    return (
      <Form.Item
        label="Template Type"
        name="templateDataType"
        rules={[{ required: true, message: 'Please select a Template Type' }]}
        tooltip={{ title: 'Select the template type based on the nature of the data', icon: <InfoCircleOutlined /> }}
      >
        <Select
          showSearch
          value={templateDataType}
          placeholder="Select a Template Type"
          optionFilterProp="children"
          onChange={setTemplateDataTypeField}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(templateDataTypeOptions || []).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
        />
      </Form.Item>
    )
  }

  const setTemplateDataTypeField = (e) => {
    setTemplateDataType(e);
    setInsuranceType(null);
    form.setFieldsValue({
      insuranceType: null
    });
  }


  const populateInsuranceTypeField = () => {
    let temp_list = [];
    let insuranceTypeOptions = (supportedDataTypes || []).map(i => {
      if (temp_list.includes(i.insurance_type)) {
        return null;
      }
      if (templateDataType !== undefined && templateDataType !== null) {
        if (i.data_type === templateDataType) {
          temp_list.push(i.insurance_type)
          return { value: i.insurance_type, label: humanize(i.insurance_type) + ' Insurance' }
        } else {
          return null
        }
      }
      temp_list.push(i.insurance_type)
      return { value: i.insurance_type, label: humanize(i.insurance_type) + ' Insurance' }
    })
    insuranceTypeOptions = insuranceTypeOptions.filter((x) => x ? true : false)
    return (
      <Row>
        <Col offset={4} span={14}>
          <Form.Item
            label="Insurance Type"
            name="insuranceType"
            rules={[{ required: true, message: 'Please select an Insurance Type' }]}
            tooltip={{ title: 'Select the product/insurance type', icon: <InfoCircleOutlined /> }}
          >
            <Select
              showSearch
              // style={{width: '50%', marginRight:10}}
              value={insuranceType}
              placeholder="Select an Insurance Type"
              optionFilterProp="children"
              onChange={setInsuranceType}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={(insuranceTypeOptions || []).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
            />

          </Form.Item>
        </Col>
        <Col span={4}>
          <Button style={{ marginLeft: 10 }} onClick={downloadTemplate} disabled={!insuranceType || !templateDataType}><DownloadOutlined />Template</Button>
        </Col>
      </Row>
    )
  }

  const uploadCSV = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    let timestamp = Math.floor(Date.now() / 1000)
    setTimestamp(timestamp)

    setUploadedFile(file)

    let data = {
      insurance_type: insuranceType,
      data_type: templateDataType,
      document_type: documentType,
      timestamp: timestamp,
      files: [{ file_name: file.name, content_type: 'text/csv' }]
    }

    var formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    await axios.post(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/pre-signed-url`, data, { headers: { 'Authorization': 'Bearer ' + user.token } })
      .then(res => {
        axios.put(res.data[0].url, file, config)
          .then(res => { onSuccess('Ok') }).catch(err => console.log(err))
      })
      .catch(err => {
        console.log(err)
        onError(err)
      })
  };

  const populateUploadField = () => {
    let uploadProps = {
      name: 'file',
      accept: '.csv',
      multiple: false,
      customRequest: uploadCSV,
      onChange(info) {
        const { status } = info.file;
        if (status === 'uploading') {
          setLoading(true)
        }
        if (status === 'done') {
          setLoading(false)
        } else if (status === 'error') {
          setLoading(false)
        }
      }
    };
    return (
      <Form.Item
        label="Upload CSV File"
        name="uploadCSVFile"
        rules={[{ required: true, message: 'Please select a CSV file to upload' }]}
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Only csv file is supported.
          </p>
        </Dragger>
      </Form.Item>
    )
  }

  const onFinish = (values) => {
    let systemMetadata = {
      csv_file_name: uploadedFile.name,
      timestamp: timestamp,
      docuemnt_type: documentType,
      source: 'OFFLINE',
      template_data_type: templateDataType,
      insurance_type: insuranceType,
    }
    let blob = new Blob([JSON.stringify(systemMetadata)], { type: 'text/plain' });
    let systemMetadataFile = new File([blob], 'SYSTEM_METADATA.json');
    let data = {
      insurance_type: insuranceType,
      data_type: templateDataType,
      document_type: documentType,
      timestamp: timestamp,
      files: [{ file_name: systemMetadataFile.name, content_type: systemMetadataFile.type }]
    }
    axios.post(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/pre-signed-url`, data, { headers: { 'Authorization': 'Bearer ' + user.token } })
      .then(res => {
        axios.put(res.data[0].url, systemMetadataFile, { headers: { 'Content-Type': systemMetadataFile.type } })
          .then(res => {
            notificationApi['success']({ message: 'File Uploaded', description: "File has been successfully submitted for processing." });
            form.resetFields()
            setInsuranceType(null)
            setTemplateDataType(null)
            setTimestamp(null)
            setUploadedFile(null)
          })
          .catch(err => console.log(err))
      })
      .catch(err => {
        notificationApi['error']({ message: 'File Upload Failed', description: "File upload has failed. Please contact support for resolution of this issue." });
      })
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row justify="left">
      {contextHolder}
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Space direction='vertical' size="large" style={{ display: 'flex' }}>
          <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Title>Bulk Policy Upload</Title>
          </Space>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={{ docuemntType: 'TEMPLATES' }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            {populateDocumentTypeField()}
            {populateTemplateDataTypeField()}
            {populateInsuranceTypeField()}
            {populateUploadField()}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Col>
    </Row>
  );
};
export default BulkUpload;