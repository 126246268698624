import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { Col, Row, Space, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, notification, Select, Alert, Popconfirm } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { humanize } from '../utils';
import { Upload } from 'antd';
import axios from 'axios';
const { Dragger } = Upload;
const { Title } = Typography;

const MasterUpload = (props) => {
  const { user } = props
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [masterType, setMasterType] = useState(null)
  const [supportedDataTypes, setSupportedDataTypes] = useState(null)
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [timestamp, setTimestamp] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [masterDownloadType, setMasterDownloadType] = useState(null)
  const [csvfile, setCsvFile] = useState(null)

  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/master`, { headers: { 'Authorization': 'Bearer ' + user.token } })
      .then(res => {
        console.log('====================================');
        console.log(res.data);
        console.log('====================================');
        setSupportedDataTypes(res.data)
      })
  }, [user.token])

  const populateMasterType = () => {
    let temp_list = [];
    let templateDataTypeOptions = (supportedDataTypes || []).map(i => {
      if (temp_list.includes(i.name)) {
        return null;
      }
      temp_list.push(i.name)
      return { value: i.name, label: humanize(i.name) }
    })
    templateDataTypeOptions = templateDataTypeOptions.filter((x) => x ? true : false)
    return (
      <Form.Item
        label="Master Type"
        name="masterType"
        rules={[{ required: true, message: 'Please select a Master Type' }]}
        tooltip={{ title: 'Select the Master Type', icon: <InfoCircleOutlined /> }}
      >
        <Select
          showSearch
          value={masterType}
          placeholder="Select a Master Type"
          optionFilterProp="children"
          onChange={setMasterType}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(templateDataTypeOptions || []).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
        />
      </Form.Item>
    )
  }


  const downloadMaster = () => {
    let query_params = []
    query_params.push(`name=${masterType}`)
    console.log(query_params)
    axios.get(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/master/download?${query_params.join('&')}`,
      { headers: { 'Authorization': 'Bearer ' + user.token }, responseType: 'blob' })
      .then(res => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `MasterData.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
  }



  const uploadCSV = () => {
    const formData = new FormData();
    formData.append('file', csvfile);
    let query_params = []
    query_params.push(`name=${masterType}`)
    axios.post(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/master/upload?${query_params.join('&')}`, formData, { headers: { 'Authorization': 'Bearer ' + user.token } })
      .then(res => {
        notificationApi['success']({ message: 'File Uploaded', description: "File has been successfully submitted for processing." });
        console.log(res)
      })
      .catch(err => {
        notificationApi['error']({ message: 'File Upload Failed', description: "File upload has failed. There might be duplicate items or some other issue. Please contact support for resolution of this issue." });
        console.log(err)

      });
  };

  const populateUploadField = () => {
    let uploadProps = {
      name: 'file',
      accept: '.csv',
      multiple: false,
      beforeUpload: (file) => {
        setCsvFile(file);
        return false;
      },
      onChange(info) {
        const { status } = info.file;
        if (status === 'uploading') {
          setLoading(true)
        }
        if (status === 'done') {
          setLoading(false)
        } else if (status === 'error') {
          setLoading(false)
        }
      }
    };
    return (
      <Form.Item
        label="Upload CSV File"
        name="uploadCSVFile"
        rules={[{ required: true, message: 'Please select a CSV file to upload' }]}
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Only csv file is supported.
          </p>
        </Dragger>
      </Form.Item>
    )
  }
  return (
    <Row justify="left">
      {contextHolder}
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Space direction='vertical' size="large" style={{ display: 'flex' }}>
          <div>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
              <Title>Master Upload</Title>
            </Space>
          </div>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={{ docuemntType: 'TEMPLATES' }}
            autoComplete="off"
            form={form}
          >
            {populateMasterType()}
            {populateUploadField()}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button style={{ marginLeft: 16 }} onClick={downloadMaster} >Download Master</Button>
              <Button style={{ marginLeft: 16 }} onClick={uploadCSV} >Upload CSV</Button>
            </Form.Item>
          </Form>
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      </Col>
    </Row>
  );
};

export default MasterUpload;