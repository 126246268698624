import React, { useState, useEffect } from 'react';
import { Button, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Col, Row, Space, Select, DatePicker } from 'antd';
import { notification } from 'antd';
import { humanize } from '../utils';
import axios from 'axios';
import dayjs from 'dayjs';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Reports = (props) => {
  const { user } = props
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [insuranceType, setInsuranceType] = useState(null);
  const [reportName, setReportName] = useState(null);
  const [supportedDataTypes, setSupportedDataTypes] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/reports`, { headers: { 'Authorization': 'Bearer ' + user.token } })
      .then(res => {
        console.log('====================================');
        console.log(res.data);
        console.log('====================================');
        setSupportedDataTypes(res.data)
      })
  }, [user.token])

  const onFinancialYearChange = (e) => {
    e = new Date(e[0]);

    form.setFieldsValue({
      FINANCIAL_YEAR: [dayjs(e), dayjs(new Date(e.getFullYear() + 1, e.getMonth(), e.getDate()))]
    });
  }

  const populateInsuranceTypeField = () => {
    const unique = [...new Set(supportedDataTypes.map(item => item.insurance_type))];
    let insuranceTypeOptions = unique.map(item => { return { value: item, label: humanize(item) } })
    return (
      <Form.Item
        label="Insurance Type"
        name="insuranceType"
        rules={[{ required: true, message: 'Please select an Insurance Type' }]}
        tooltip={{ title: 'Select the product/insurance type', icon: <InfoCircleOutlined /> }}
      >
        <Select
          showSearch
          value={insuranceType}
          placeholder="Select an Insurance Type"
          optionFilterProp="children"
          onChange={setInsuranceType}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(insuranceTypeOptions || []).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
        />

      </Form.Item>
    )
  }

  const populateReportNameField = () => {
    const filteredData = supportedDataTypes.filter(item => item.insurance_type === insuranceType)
    const arrayUniqueByKey = [...new Map(filteredData.map(item => [item['name'], item])).values()];
    let reportNameOptions = arrayUniqueByKey.map(item => { return { value: item.name, label: item.label } })
    console.log(reportNameOptions);
    return (
      <Form.Item
        label="Report Name"
        name="name"
        rules={[{ required: true, message: 'Please select Report Name' }]}
        tooltip={{ title: 'Select the report name for which you want to generate the report.', icon: <InfoCircleOutlined /> }}
      >
        <Select
          showSearch
          value={reportName}
          placeholder="Select a Report Name"
          optionFilterProp="children"
          onChange={setReportName}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(reportNameOptions || []).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
        />
      </Form.Item>
    )
  }

  const populateFieldForReportType = () => {
    let data = supportedDataTypes.filter(x => x.name === reportName)
    let result = []
    for (let item of data) {
      if (item.field_type === 'DATE_RANGE_SELECTOR') {
        result.push(<Form.Item
          label={humanize(item.field_name)}
          name={item.field_name}
          rules={[{ required: true, message: `Please select ${item.label}` }]}
          tooltip={{ title: `Select the ${item.label}.`, icon: <InfoCircleOutlined /> }}
        >
          <RangePicker />
        </Form.Item>)

      } else if (item.field_type === 'BACKWARD_DATE_RANGE_SELECTOR') {
        result.push(<Form.Item
          label={humanize(item.field_name)}
          name={item.field_name}
          rules={[{ required: true, message: `Please select ${item.label}` }]}
          tooltip={{ title: `Select the ${item.label}.`, icon: <InfoCircleOutlined /> }}
        >
          <RangePicker disabledDate={(current) => current > dayjs().endOf('day')} />
        </Form.Item>)
      } else if (item.field_type === 'SINGLE_FUTURE_DATE_SELECTOR') {
        result.push(<Form.Item
          label={humanize(item.field_name)}
          name={item.field_name}
          rules={[{ required: true, message: `Please select ${item.label}` }]}
          tooltip={{ title: `Select the ${item.label}.`, icon: <InfoCircleOutlined /> }}
        >
          <DatePicker disabledDate={(current) => current && current < dayjs().endOf('day')} />
        </Form.Item>)
      } else if (item.field_type === 'SINGLE_DATE_SELECTOR') {
        result.push(<Form.Item
          label={humanize(item.field_name)}
          name={item.field_name}
          rules={[{ required: true, message: `Please select ${item.label}` }]}
          tooltip={{ title: `Select the ${item.label}.`, icon: <InfoCircleOutlined /> }}
        >
          <DatePicker />
        </Form.Item>)
      } else if (item.field_type === 'FINANCIAL_YEAR_SELECTOR') {
        result.push(<Form.Item
          label={humanize(item.field_name)}
          name={item.field_name}
          rules={[{ required: true, message: `Please select ${item.label}` }]}
          tooltip={{ title: `Select the ${item.label}.`, icon: <InfoCircleOutlined /> }}
        >
          <RangePicker picker="year" disabled={[false, true]} onChange={onFinancialYearChange} />
        </Form.Item>)
      } else if (item.field_type === 'MONTH_AND_YEAR_SELECTOR') {
        result.push(<Form.Item
          label={humanize(item.field_name)}
          name={item.field_name}
          rules={[{ required: true, message: `Please select ${item.label}` }]}
          tooltip={{ title: `Select the ${item.label}.`, icon: <InfoCircleOutlined /> }}
        >
          <DatePicker picker="month" />
        </Form.Item>)
      }
    }
    return result;
  }

  const onFinish = (values) => {
    console.log('onFinish', values)
    axios.post(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/reports`, values, { headers: { 'Authorization': 'Bearer ' + user.token }, responseType: 'blob' })
      .then(res => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `export.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        form.resetFields();
      })
      .catch(err => {
        notificationApi['error']({ message: 'Failed to generate report', description: "Failed to generate report. Please contact support for resolution of this issue." });
      })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row justify="left">
      {contextHolder}
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Space direction='vertical' size="large" style={{ display: 'flex' }}>
          <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Title>Reports</Title>
          </Space>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            {populateInsuranceTypeField()}
            {populateReportNameField()}
            {populateFieldForReportType()}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Generate
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Col>
    </Row>
  );
};
export default Reports;