import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { humanize } from "../utils";

const { Title } = Typography;

const FailedRecordCenter = (props) => {
  const { user } = props;
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [templateDataType, setTemplateDataType] = useState(null);
  const [insuranceType, setInsuranceType] = useState(null);
  const [errorData, setErrorData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [supportedDataTypes, setSupportedDataTypes] = useState(null);
  const [columns, setColumns] = useState([]);
  const staticColumn = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        console.log("record", record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Sure to reject?"
              onConfirm={() => {
                resolveRecord(record);
              }}>
              <a>Reject</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/data-types`, {
        headers: { Authorization: "Bearer " + user.token },
      })
      .then((res) => {
        console.log("====================================");
        console.log(res.data);
        console.log("====================================");
        setSupportedDataTypes(res.data);
      });
  }, [user.token]);

  const populateTemplateDataTypeField = () => {
    let temp_list = [];
    let templateDataTypeOptions = (supportedDataTypes || []).map((i) => {
      if (temp_list.includes(i.data_type)) {
        return null;
      }
      temp_list.push(i.data_type);
      return { value: i.data_type, label: humanize(i.data_type) };
    });
    templateDataTypeOptions = templateDataTypeOptions.filter((x) =>
      x ? true : false
    );
    return (
      <Form.Item
        label="Template Type"
        name="templateDataType"
        rules={[{ required: true, message: "Please select a Template Type" }]}
        tooltip={{
          title: "Select the template type based on the nature of the data",
          icon: <InfoCircleOutlined />,
        }}>
        <Select
          showSearch
          value={templateDataType}
          placeholder="Select a Template Type"
          optionFilterProp="children"
          onChange={setTemplateDataTypeField}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={(templateDataTypeOptions || []).sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          )}
        />
      </Form.Item>
    );
  };

  const setTemplateDataTypeField = (e) => {
    setTemplateDataType(e);
    setInsuranceType(null);
    form.setFieldsValue({
      insuranceType: null,
    });
  };

  const populateInsuranceTypeField = () => {
    let temp_list = [];
    let insuranceTypeOptions = (supportedDataTypes || []).map((i) => {
      if (temp_list.includes(i.insurance_type)) {
        return null;
      }
      if (templateDataType !== undefined && templateDataType !== null) {
        if (i.data_type === templateDataType) {
          temp_list.push(i.insurance_type);
          return {
            value: i.insurance_type,
            label: humanize(i.insurance_type) + " Insurance",
          };
        } else {
          return null;
        }
      }
      temp_list.push(i.insurance_type);
      return {
        value: i.insurance_type,
        label: humanize(i.insurance_type) + " Insurance",
      };
    });
    insuranceTypeOptions = insuranceTypeOptions.filter((x) =>
      x ? true : false
    );
    return (
      <Form.Item
        label="Insurance Type"
        name="insuranceType"
        rules={[{ required: true, message: "Please select an Insurance Type" }]}
        tooltip={{
          title: "Select the product/insurance type",
          icon: <InfoCircleOutlined />,
        }}>
        <Select
          showSearch
          value={insuranceType}
          placeholder="Select an Insurance Type"
          optionFilterProp="children"
          onChange={setInsuranceType}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={(insuranceTypeOptions || []).sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          )}
        />
      </Form.Item>
    );
  };

  const exportExcel = () => {
    let query_params = [];
    query_params.push(`insurance_type=${insuranceType}`);
    query_params.push(`data_type=${templateDataType}`);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/error-logs/export?${query_params.join("&")}`,
        {
          headers: { Authorization: "Bearer " + user.token },
          responseType: "blob",
        }
      )
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Processing_Failed_Policies.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const clearAllRecords = () => {
    let query_params = [];
    query_params.push(`insurance_type=${insuranceType}`);
    query_params.push(`data_type=${templateDataType}`);
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/error-logs/resolve/all?${query_params.join("&")}`,
        null,
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then((res) => {
        notificationApi["success"]({
          message: "Cleared All Failed Records",
          description: "All failed records are now marked resovled.",
        });
      })
      .catch((err) => {
        notificationApi["error"]({
          message: "Failed to clear Items",
          description:
            "Failed to clear Action Items. Please contact support for resolution of this issue.",
        });
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    let query_params = [];
    query_params.push(`insurance_type=${insuranceType}`);
    query_params.push(`data_type=${templateDataType}`);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/error-logs?${query_params.join("&")}`,
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then((res) => {
        setErrorData(res.data.data);
        let temp = res.data.columns;
        temp.push(...staticColumn);
        console.log("temp", temp);
        setColumns(temp);
        setTotalData(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        notificationApi["error"]({
          message: "failed to get Action Items",
          description:
            "Failed to get Action Items. Please contact support for resolution of this issue.",
        });
      });
  };

  const resolveRecord = (record) => {
    let query_params = [];
    query_params.push(`insurance_type=${insuranceType}`);
    query_params.push(`data_type=${templateDataType}`);
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/error-logs/resolve/${record.id}?${query_params.join(
            "&"
          )}`,
        {},
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then((res) => {
        setErrorData((errorData) =>
          errorData.filter((item) => item.id !== record.id)
        );
      })
      .catch((err) => {
        notificationApi["error"]({
          message: "Failed to Delete",
          description:
            "Failed to delete the item. Please contact support for resolution of this issue.",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const paginateData = (e) => {
    setLoading(true);
    let query_params = [];
    query_params.push(`insurance_type=${insuranceType}`);
    query_params.push(`data_type=${templateDataType}`);
    query_params.push(`page_number=${e}`);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/error-logs?${query_params.join("&")}`,
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then((res) => {
        setErrorData(res.data.data);
        setTotalData(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        notificationApi["error"]({
          message: "failed to get Action Items",
          description:
            "Failed to get Action Items. Please contact support for resolution of this issue.",
        });
      });
  };

  return (
    <Row justify="left">
      {contextHolder}
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <div>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}>
              <Title>Failed Records</Title>
            </Space>
            <Alert
              style={{ marginLeft: "20px" }}
              message="Policy Records that were unprocessed because of either System or Data issues. Please review the Data issues, correct them, and re-upload the Policy records via the MIS portal. In case of a System Issue, please contact MIS System Admin"
              type="info"
              showIcon
            />
          </div>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={{ docuemntType: "TEMPLATES" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}>
            {populateTemplateDataTypeField()}
            {populateInsuranceTypeField()}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Button
          style={{ marginLeft: 16 }}
          onClick={exportExcel}
          disabled={templateDataType === null || insuranceType === null}>
          Export to Excel
        </Button>
        <Button
          style={{ marginLeft: 16 }}
          onClick={clearAllRecords}
          disabled={templateDataType === null || insuranceType === null}>
          Clear All Records
        </Button>
        <Table
          loading={loading}
          columns={columns}
          rowKey={"ID"}
          expandable={{
            expandedRowRender: (record) => (
              <pre
                style={{
                  margin: 0,
                }}>
                {JSON.stringify(record.data, undefined, 4)}
              </pre>
            ),
          }}
          dataSource={errorData}
          style={{ margin: "20px" }}
          pagination={{
            total: totalData,
            pageSize: 20,
            onChange: paginateData,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};
export default FailedRecordCenter;
