import React, { useEffect, useState } from 'react';
import { CloudUploadOutlined, ExclamationCircleOutlined, UploadOutlined, TableOutlined, DatabaseOutlined} from '@ant-design/icons';
import { Layout, Menu, Result, Space, Spin } from 'antd';
import BulkUpload from './components/BulkUpload';
import SinglePolicyInput from './components/SinglePolicyInput';
import FailedRecordCenter from './components/FailedRecordCenter';
import DuplicateRecordCenter from './components/DuplicateRecordCenter';
import axios from 'axios';
import Reports from './components/Reports';
import FileUploadHistory from './components/FileUploadHistory'
import MasterUpload from './components/MasterUpload';

const { Header, Content, Footer, Sider } = Layout;

const menuItems = [
  { key: 'BULK_UPLOAD', icon: React.createElement(CloudUploadOutlined), label: 'Bulk Upload' },
  { key: 'SINGLE_POLICY_INPUT', icon: React.createElement(UploadOutlined), label: 'Single Policy Input' },
  { key: 'REPORTS', icon: React.createElement(TableOutlined), label: 'Reports' },
  { key: 'ACTION_CENTER', icon: React.createElement(ExclamationCircleOutlined), label: 'Action Center', children: [
    { key: 'FAILED_CENTER', label: 'Failed Center'},
    { key: 'DUPLICATE_CENTER', label: 'Duplicate Center'}]},
  {key: 'FILE_UPLOAD_HISTORY', icon: React.createElement(UploadOutlined), label: 'File Upload History'  },
  { key: 'MASTER_UPLOAD', icon: React.createElement(DatabaseOutlined), label: 'Master Upload' }
]

const App = () => {
  let queryParams = new URLSearchParams(window.location.search)
  const auth = queryParams.get("auth")
  const [selectedPage, setSelectedPage] = useState('BULK_UPLOAD')
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    if(auth !== null && user === null && loading === false) {
      axios.get(process.env.REACT_APP_BACKEND_URL + '/data-aggregator/auth', {headers: {'Authorization': 'Bearer ' + auth}})
        .then(res => {
          setUser(res.data);
          setAuthorized(true);
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        })
      window.history.replaceState({}, document.title, "/");
      setLoading(true)
    }
  }, [auth, user, loading])

  return (
    authorized ? (
      <Layout>
        <Header className="header" theme='dark'>
          <div className="logo"><span style={{fontSize: '30px', color: 'white', fontWeight: 700}}>MIS</span></div>
        </Header>
        <Layout>
          <Sider
            theme='dark'
            breakpoint="lg"
            collapsedWidth="0"
          >
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[selectedPage]}
              onClick={(e) => setSelectedPage(e.key)}
              items={menuItems}
            />
          </Sider>
          <Layout>
            <Content
              style={{
                margin: '16px 16px 0',
              }}
            >
              <div
                className="site-layout-background"
                style={{
                  minHeight: '83vh',
                  background: 'white'
                }}
              >
                {
                  selectedPage === 'BULK_UPLOAD' ?
                    <BulkUpload user={user}/> :
                    selectedPage === 'SINGLE_POLICY_INPUT' ?
                      <SinglePolicyInput user={user}/> :
                      selectedPage === 'FAILED_CENTER' ?
                      <FailedRecordCenter user={user}/> :
                      selectedPage === 'REPORTS' ?
                      <Reports user={user}/> :
                      selectedPage === 'DUPLICATE_CENTER' ?
                      <DuplicateRecordCenter user={user}/> :
                      selectedPage === 'FILE_UPLOAD_HISTORY' ?
                      <FileUploadHistory user={user}/> :
                      selectedPage === 'MASTER_UPLOAD' ?
                      <MasterUpload user={user}/> :
                      <SinglePolicyInput user={user}/>
                }
              </div>
            </Content>
            <Footer
              style={{
                textAlign: 'center',
              }}
            >
              Ensuredit © 2023 Created by Ensuredit
            </Footer>
          </Layout>

        </Layout>
      </Layout>
    )
      : loading ? 
      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
        <Space direction="vertical" style={{ height: '100vh', justifyContent: 'center' }}>
          <Spin size="large" />
        </Space>
      </Space>
      :
      !authorized ? 
      <Result
        status="403"
        title="401"
        subTitle="Sorry, you are not authorized to access this page. Please come back from ICE App."
      />
      : null
  )
};

export default App;