export function humanize(str) {
  var i,
    frags = str?.split("_");
  if (frags) {
    for (i = 0; i < frags.length; i++) {
      frags[i] =
        frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
    }
    return frags.join(" ");
  }
}
