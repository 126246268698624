import { Col, notification, Row, Space, Table, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const { Title } = Typography;

const FileUploadHistory = (props) => {
  const [loading, setLoading] = useState(false);
  const { user } = props;

  const [dataSource, setDataSource] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [pageNumber, setPageNumber] = useState(1);
  // const columns = [
  //     {
  //       title: 'File Name',
  //       dataIndex: 'FILENAME',
  //       key: 'FILENAME',
  //     },
  //     {
  //       title: 'Duplicate Records',
  //       dataIndex: 'DUPLICATES',
  //       key: 'DUPLICATES',
  //     },
  //     {
  //       title: 'Failed Records',
  //       dataIndex: 'FAILED',
  //       key: 'FAILED',
  //     },
  //     {
  //         title: 'Accepted Records',
  //         dataIndex: 'ACCEPTED',
  //         key: 'ACCEPTED',
  //       },
  //       {
  //         title: 'Total Records',
  //         dataIndex: 'TOTAL',
  //         key: 'TOTAL',
  //       },
  //       {
  //         title: 'Time Uploaded',
  //         dataIndex: 'TIMESTAMP',
  //         key: 'TIMESTAMP',
  //       },
  //       {
  //         title: 'Insurance Type',
  //         dataIndex: 'INSURANCE_TYPE',
  //         key: 'INSURANCE_TYPE',
  //       },
  //       {
  //         title: 'Data Type',
  //         dataIndex: 'DATA_TYPE',
  //         key: 'DATA_TYPE',
  //       }
  //   ];

  const columns = [
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
    },
    {
      title: "Duplicate Records",
      dataIndex: "duplicates",
      key: "duplicates",
    },
    {
      title: "Failed Records",
      dataIndex: "failed",
      key: "failed",
    },
    {
      title: "Accepted Records",
      dataIndex: "accepted",
      key: "accepted",
    },
    {
      title: "Total Records",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Product",
      dataIndex: "insurance_type",
      key: "insurance_type",
    },
    {
      title: "Data Type",
      dataIndex: "data_type",
      key: "data_type",
    },
    // {
    //   title: "Uploaded at",
    //   key: "created_at",
    //   dataIndex: "created_at",
    //   render: (text) => {
    //     const ist = new Date(new Date(text).toISOString()).toLocaleString({
    //       timeZone: "Asia/Kolkata",
    //     });

    //     return ist;
    //   },
    // },
  ];
  const paginateData = (e) => {
    setLoading(true);
    let query_params = [];
    query_params.push(`page_number=${e}`);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/file_upload_history?${query_params.join("&")}`,
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then((res) => {
        const record = res.data;
        console.log("hi", record.data);
        setDataSource(record.data);
        setTotalData(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        notificationApi["error"]({
          message: "failed to get file upload history",
          description:
            "Failed to get file upload history. Please contact support for resolution of this issue.",
        });
      });
  };

  useEffect(() => {
    paginateData(pageNumber);
  }, []);

  return (
    <Row justify="left">
      {contextHolder}
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <div>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}>
              <Title>File Upload History</Title>
            </Space>
          </div>
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          rowKey={"ID"}
          style={{ margin: "20px" }}
          pagination={{
            total: totalData,
            pageSize: 20,
            onChange: paginateData,
            showSizeChanger: false,
          }}
        />
      </Col>
    </Row>
  );
};
export default FileUploadHistory;
