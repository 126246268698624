import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { humanize } from "../utils";

const { Title } = Typography;

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

const SinglePolicyInput = (props) => {
  const { user } = props;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [formData] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [insuranceType, setInsuranceType] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [fieldDataModified, setFieldDataModified] = useState(null);
  const [supportedDataTypes, setSupportedDataTypes] = useState(null);
  let forceUpdate = useForceUpdate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/data-aggregator/data-types`, {
        headers: { Authorization: "Bearer " + user.token },
      })
      .then((res) => {
        console.log(res.data);
        let result = [];
        for (let item of res.data) {
          if (item?.single_policy_input) {
            result.push(item);
          }
        }
        setSupportedDataTypes(result);
      });
  }, [user.token]);

  const populateInsuranceTypeField = () => {
    let temp_list = [];
    let insuranceTypeOptions = (supportedDataTypes || []).map((i) => {
      if (temp_list.includes(i.insurance_type)) {
        return null;
      }

      temp_list.push(i.insurance_type);
      return {
        value: i.insurance_type,
        label: humanize(i.insurance_type) + " Insurance",
      };
    });
    insuranceTypeOptions = insuranceTypeOptions.filter((x) =>
      x ? true : false
    );
    console.log("====================================");
    console.log(insuranceTypeOptions);
    console.log("====================================");
    return (
      <Form.Item
        label="Insurance Type"
        name="insuranceType"
        rules={[{ required: true, message: "Please select an Insurance Type" }]}
        tooltip={{
          title: "Select the product/insurance type",
          icon: <InfoCircleOutlined />,
        }}>
        <Select
          showSearch
          value={insuranceType}
          placeholder="Select an Insurance Type"
          optionFilterProp="children"
          onChange={setInsuranceType}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={(insuranceTypeOptions || []).sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          )}
        />
      </Form.Item>
    );
  };

  const onFinish = () => {
    let query_params = [];
    query_params.push(`insurance_type=${insuranceType}`);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/single-upload?${query_params.join("&")}`,
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then((res) => {
        console.log(res);
        setFieldData(res.data);
        setFieldDataModified(res.data);
      })
      .catch(() => {
        notificationApi["error"]({
          message: "Failed to show form",
          description:
            "Failed to show input form. Please contact support for resolution of this issue.",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const setDropdownOptions = () => {
    let data = formData.getFieldsValue(true);
    for (let i in data) {
      if (data[i] === undefined || data[i] === null || data[i] === "") {
        delete data[i];
      }
    }
    let temp = fieldData;
    for (let item of temp) {
      if (
        item.field_type === "dropdown" &&
        typeof item.options[0] === "object" &&
        Object.keys(item.options[0]).includes(item.field_name)
      ) {
        console.log(item.field_name in Object.keys(item.options[0]));
        let filter_keys = Object.keys(item.options[0]).filter((value) =>
          Object.keys(data).includes(value)
        );
        if (filter_keys.length > 0) {
          let filtered_options = item.options.filter((value) => {
            let result = true;
            for (let x of filter_keys) {
              result = result && value[x] === data[x];
            }
            return result;
          });
          item.options = filtered_options;
        }
      }
    }
    setFieldDataModified(temp);
    forceUpdate();
  };

  const evaluateMandation = (condition) => {
    if (condition === null) {
      return false;
    }
    if ("$and" in condition) {
      let result = true;
      for (let item of condition["$and"]) {
        result = result && evaluateMandation(item);
      }
      return result;
    } else if ("$or" in condition) {
      let result = false;
      for (let item of condition["$or"]) {
        result = result || evaluateMandation(item);
      }
      return result;
    } else if ("$not" in condition) {
      return !evaluateMandation(condition["$not"]);
    } else {
      if (
        formData.getFieldsValue(true)[Object.keys(condition)[0]] ===
        condition[Object.keys(condition)[0]]
      ) {
        return true;
      }
      return false;
    }
  };

  const resolveField = (item) => {
    if (item.field_type === "string") {
      return (
        <Col span={12}>
          <Form.Item
            label={item.label}
            name={item.field_name}
            rules={[
              {
                required:
                  item.mandatory ||
                  evaluateMandation(item.conditional_mandation),
                message: `Please input a ${item.label}`,
                pattern: item.pattern,
              },
            ]}>
            <Input placeholder={item.label} />
          </Form.Item>
        </Col>
      );
    }
    if (item.field_type === "integer" || item.field_type === "float") {
      return (
        <Col span={12}>
          <Form.Item
            label={item.label}
            name={item.field_name}
            rules={[
              {
                required:
                  item.mandatory ||
                  evaluateMandation(item.conditional_mandation),
                message: `Please input a ${item.label}`,
              },
            ]}>
            <InputNumber placeholder={item.label} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      );
    }
    if (item.field_type === "date") {
      return (
        <Col span={12}>
          <Form.Item
            label={item.label}
            name={item.field_name}
            rules={[
              {
                required:
                  item.mandatory ||
                  evaluateMandation(item.conditional_mandation),
                message: `Please input a ${item.label}`,
              },
            ]}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      );
    }
    if (item.field_type === "dropdown") {
      let options = [];
      if (item.options !== null && typeof item?.options[0] === "object") {
        const unique = [
          ...new Set(
            item?.options?.map((x) => x[item?.lookup_master_column_name])
          ),
        ];
        for (let x of unique) {
          options.push({ value: x, label: x });
        }
      } else {
        if (item.options !== null) {
          for (let x of item?.options) {
            options.push({ value: x, label: x });
          }
        }
      }
      return (
        <Col span={12}>
          <Form.Item
            label={item.label}
            name={item.field_name}
            rules={[
              {
                required:
                  item.mandatory ||
                  evaluateMandation(item.conditional_mandation),
                message: `Please input a ${item.label}`,
              },
            ]}>
            <Select
              showSearch
              placeholder={`Select ${item.label}`}
              optionFilterProp="children"
              allowClear={true}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={options}
            />
          </Form.Item>
        </Col>
      );
    }
    return null;
  };

  const renderForm = () => {
    let temp = {};
    for (let item of fieldDataModified) {
      if (item.category in temp) {
        temp[item.category].push(item);
      } else {
        temp[item.category] = [item];
      }
    }
    for (let item in temp) {
      let sub_category = {};
      let non_category = [];
      for (let i of temp[item]) {
        if (i.sub_category !== null) {
          if (i.sub_category in sub_category) {
            sub_category[i.sub_category].push(i);
          } else {
            sub_category[i.sub_category] = [i];
          }
        } else {
          non_category.push(i);
        }
      }
      non_category.push({ $sub_category: sub_category });
      temp[item] = non_category;
    }
    return (
      <Collapse accordion>
        {Object.keys(temp).map((category) => {
          return (
            <Panel header={category} key={category}>
              <Row>
                {temp[category].map((item) => {
                  if ("$sub_category" in item) {
                    return (
                      <Collapse accordion style={{ width: "100%" }}>
                        {Object.keys(item["$sub_category"]).map(
                          (sub_category) => {
                            return (
                              <Panel header={sub_category} key={sub_category}>
                                <Row>
                                  {item["$sub_category"][sub_category].map(
                                    (i) => resolveField(i)
                                  )}
                                </Row>
                              </Panel>
                            );
                          }
                        )}
                      </Collapse>
                    );
                  }
                  return resolveField(item);
                })}
              </Row>
            </Panel>
          );
        })}
      </Collapse>
    );
  };

  const onFinishSubmitData = (values) => {
    console.log("Success:", values);
    for (let i in values) {
      if (typeof values[i] === "object") {
        values[i] = values[i].toJSON().slice(0, 10);
      }
    }
    let query_params = [];
    query_params.push(`insurance_type=${insuranceType}`);
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          `/data-aggregator/single-upload?${query_params.join("&")}`,
        values,
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then(() => {
        notificationApi["success"]({
          message: "Data submitted",
          description:
            "Data has been successfully submitted. It will be further processed.",
        });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          notificationApi["error"]({
            message: "Invalid Data",
            description: err.response.data.detail,
          });
        } else {
          notificationApi["error"]({
            message: "Failed to submit data",
            description:
              "Failed to submit the data. Please contact support for further information.",
          });
        }
      });
  };

  return (
    <Row justify="left">
      {contextHolder}
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}>
            <Title>Single Policy Input</Title>
          </Space>
          {fieldData ? (
            <Row
              justify={"center"}
              style={{ margin: 20, marginBottom: 40, marginTop: 0 }}>
              <Title level={5}>{humanize(insuranceType) + " Insurance"}</Title>
              <Form
                name="basic"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                onFinish={onFinishSubmitData}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={formData}
                labelWrap
                onValuesChange={setDropdownOptions}
                style={{ width: "100%" }}>
                {renderForm()}

                <Col span={24} style={{ marginTop: 15 }}>
                  <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          ) : (
            <Row justify={"center"} style={{ margin: 20 }}>
              <Col span={12}>
                <Form
                  name="basic"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  initialValues={{ docuemntType: "TEMPLATES" }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}>
                  {populateInsuranceTypeField()}

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}
        </Space>
      </Col>
    </Row>
  );
};
export default SinglePolicyInput;
